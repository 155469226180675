<template>
  <div class="routerList" @contextmenu.prevent="() => showSearch = true" @dblclick="toggleAutoScorll">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <i class="iconfont icon-vector"/>
        </span>
        <div class="d-flex">
          <span class="text mx-2">{{ t('cableCenter.cableList') }}</span>
        </div>
      </div>
      <el-table ref="scrollBoard" :data="tableData" height="405" @row-click="onRowClick" :show-header="false">
        <el-table-column :label="t('cableCenter.cableName')" prop="name" min-width="200">
          <template #default="{row}">
            {{getCableName(row)}}
          </template>
        </el-table-column>
        <el-table-column :label="t('cableCenter.cableLength')" prop="length" width="80"
                         :formatter="(_, __, cellValue)=>cellValue + t('cableCenter.cableLengthUnit')"/>
      </el-table>
    </div>
  </div>
  <el-dialog v-model="showSearch">
    <el-form inline @keydown.enter="search">
      <el-form-item style="width: 360px">
        <el-input style="font-size: 20px" size="large" placeholder="线路名、简称、城市或安装位置区段" v-model.trim="searchInfo" @change="search" />
      </el-form-item>
      <el-form-item>
        <el-button size="large" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <div v-for="i in candidates" :key="i.id" style="height: 40px; margin: 8px 0 8px 0;">
      <el-link style="font-size: 20px" @click="onCandidateClick(i)">{{ getCableName(i) }}</el-link>
    </div>
  </el-dialog>
</template>

<script setup>
import {
  ref,
  watch,
  onMounted,
  onUnmounted,
  onUpdated,
} from "vue"
import {useStore} from "vuex"
import {getCableName} from "@/utils/cableNameGen"
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'

const { t } = useI18n()

const selectedRowClass = 'selected-row'

const scrollBoard = ref()

const tableData = ref([])
let ids = []
const rowHeights = ref([])

const store = useStore()

const autoScroll = ref(true)
let timerId
let timerOutId

function updateCables(newCables) {
  tableData.value = newCables
  ids = tableData.value.map((row) => row.id)
}

function loadData() {
  if (!store.state.cables.list || store.state.cables.list.length === 0) {
    store.dispatch('cables/request', false)
        .then(() => {
          updateCables(store.state.cables.list)
        })
  } else {
    updateCables(store.state.cables.list)
  }
  setTimeout(()=> setCurrent(store.state.cables.current), 1000)
}

watch(() => store.state.cables.list.length,
    () => {
      if (!store.state.cables.current) {
        setTimeout(() => {
          setCurrent(store.state.cables.list[0].id)
        }, 1000)
      }
    })

function onRowClick(e) {
  _setCurrent(e.id)
}

function isAllZero(list) {
  for (let i of list) {
    if (i !== 0) {
      return false
    }
  }
  return true
}

function _setCurrent(id) {
  store.commit('cables/setCurrent', id)
  highlightCurrent(id)
}

function setCurrent(id) {
  _setCurrent(id)
  scroll2Current(id)
}

function scroll2Current(id) {
  if (!rowHeights.value || rowHeights.value.length < 1 || isAllZero(rowHeights.value)) {
    getHeights()
  }
  let index = ids.indexOf(id)
  let init = 0
  let offset = rowHeights.value.slice(0, index).reduce((prev, curr) => prev + curr, init)
  scrollBoard.value.setScrollTop(offset)
}

function highlightCurrent(id) {
  let index = ids.indexOf(id)
  let tbody = getTableBody(scrollBoard.value.$refs.bodyWrapper.children)
  for (let i = 0; i < tbody.children.length; i++) {
    let row = tbody.children[i]
    row.classList.remove(selectedRowClass)
    if (i === index) {
      row.classList.add(selectedRowClass)
    }
  }
}

function getTableBody(list) {
  let newList = []
  for (let n of list) {
    if (n.localName === 'tbody') {
      return n
    } else {
      newList = [...newList, ...n.children]
    }
  }
  return getTableBody(newList)
}

function getHeights() {
  let tbody = getTableBody(scrollBoard.value.$refs.bodyWrapper.children)
  rowHeights.value = [...tbody.children].map((tr) => tr.clientHeight)
}

function scrollToNext() {
  let index = ids.indexOf(store.state.cables.current),
      next = index + 1 >= ids.length ? 0 : index + 1
  setCurrent(ids[next])
}

function setTimer() {
  if (timerId !== undefined) {
    clearInterval(timerId)
  }
  timerId = setInterval(() => {
    scrollToNext()
  }, 20000)
}

watch(autoScroll,
    () => {
      if (autoScroll.value) {
        setTimer()
      } else {
        clearInterval(timerId)
      }
    })

onMounted(() => {
  loadData()
  setTimer()
})

watch(() => store.state.cables.dragging,
    (dragging) => {
      if (dragging) {
        autoScroll.value = false
        clearTimeout(timerOutId)
        removeEventListener('mousemove', mouseMoveListener)
      } else {
        autoScroll.value = true
        addEventListener('mousemove', mouseMoveListener)
      }
    }
)

onUpdated(() => {
  let id = store.state.cables.current
  if (id === undefined) {
    id = store.state.cables.list[0].id
  }
  setCurrent(id)
})

onUnmounted(() => {
  clearInterval(timerId)
})


function mouseMoveListener() {
  autoScroll.value = false
  clearTimeout(timerOutId)
  timerOutId = setTimeout(() => autoScroll.value = true, 20000)
}

addEventListener('mousemove', mouseMoveListener)


// 搜索, 自动滚动开关
const showSearch = ref(false)
const searchInfo = ref('')
const candidates = ref([])

document.onkeydown = (e) => {
  if (!e.ctrlKey || !e.shiftKey) {
    return
  }
  if (e.key === 'F') {
    showSearch.value = true
  } else if (e.key === ' ') {
    toggleAutoScorll()
  }
}

function toggleAutoScorll() {
  autoScroll.value = false
  if (store.state.cables.dragging) {
    store.commit("cables/setDrag", false)
    ElMessage('自动滚动已开启')
  } else {
    store.commit("cables/setDrag", true)
    ElMessage('自动滚动已关闭')

  }
}

function search() {
  if (!searchInfo.value) {
    return
  }
  let r = []
  for (let cable of tableData.value) {
    for (let k of ['name', 'city', 'section', 'abbreviation']) {
      if (cable[k].includes(searchInfo.value)) {
        r.push(cable)
      }
    }
  }
  candidates.value = r
}

function onCandidateClick(i) {
  showSearch.value = false
  setCurrent(i.id)
}
</script>

<style lang="scss" scoped>
$box-height: 470px;
$box-width: 100%;
.routerList {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;

    .dv-scr-board {
      width: 330px;
      height: 390px;

    }
  }
}
</style>

<style>
.el-table .selected-row {
  font-weight: bold;
  --el-table-text-color: black;
  --el-table-tr-bg-color: green;
}
</style>
