<template>
  <div style="position: relative;">
    <el-link @click="onBoxValueClick" v-for="i in ['A', 'B', 'C']" :key="i" :class="getClass(i, 'junction')">
      {{ getText(i) }}
    </el-link>
    <div :class="getBoxIconClass()">
      <box-icon :id="props.box.id"/>
    </div>
    <template v-if="props.box.leftmost">
      <div v-for="i in ['A', 'B', 'C']" :key="i" :class="getClass(i, 'phase')">
        {{ i }}
      </div>
    </template>
    <div @click="onBoxNameClick" style="z-index: 999" :class="getBoxNameClass()">
      <el-link>
        {{ gndbox.name }}
      </el-link>
      <br>
      <el-link>
        {{ gndbox.deviceCode }}
      </el-link>
    </div>
    <el-link @click="onBoxValueClick" v-for="i in ['A', 'B', 'C']" :key="i" :class="getClass(i, 'ground')">
      {{ getGroundText(i) }}
    </el-link>
    <img draggable="false" v-if="gndbox.modelId===6 && props.box.leftmost"
         src="@/assets/cable-graph/terminal-left-unprotected.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===6 && props.box.rightmost"
         src="@/assets/cable-graph/terminal-right-unprotected.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===8 && props.box.leftmost"
         src="@/assets/cable-graph/terminal-left-protected.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===8 && props.box.rightmost"
         src="@/assets/cable-graph/terminal-right-protected.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===6 && props.box.leftConjunct"
         src="@/assets/cable-graph/half-left-unprotected.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===8 && props.box.leftConjunct"
         src="@/assets/cable-graph/half-left-protected.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===6 && props.box.rightConjunct"
         src="@/assets/cable-graph/half-right-unprotected.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===8 && props.box.rightConjunct"
         src="@/assets/cable-graph/half-right-protected.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===6" src="@/assets/cable-graph/unprotected.png"
         class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===7" src="@/assets/cable-graph/cross.png" class="box-img"/>
    <img draggable="false" v-else-if="gndbox.modelId===8" src="@/assets/cable-graph/protected.png" class="box-img"/>
  </div>
</template>

<script setup>
import {ref, defineProps, computed} from "vue"
import {useStore} from "vuex"
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import {useRouter} from "vue-router"

const props = defineProps(['box'])

const store = useStore()
const router = useRouter()

const gndbox = computed(() => {
  return store.getters["cables/getDeviceById"](props.box.id)
})

const ngcmd = computed(() => {
  for (let device of gndbox.value.subdevs) {
    // 环流监测设备
    if (device.modelId === 10) {
      return device
    }
  }
  return {}
})

const ngcmdValue = computed(() => {
  return store.getters["cables/getDeviceValueById"](ngcmd.value.id)
})

function getText(phase) {
  const value = ngcmdValue.value
  const runCur = value['runCur' + phase]
  const temp = value['temp' + phase]

  let s = []

  if (runCur !== undefined && runCur !== null && getEnableness('runCur', phase)) {
    s.push(runCur === 65535 ? '' : runCur + 'A')
  }
  if (temp !== undefined && temp !== null && getEnableness('temp', phase)) {
    s.push(temp === 65535 ? '' : temp + '℃')
  }
  return s.join(' ')
}

function getEnableness(valueName, phase) {
  const value = ngcmdValue.value
  for (let i of value.devCfgs) {
    if (i.dataUnit.keyword === valueName + phase && i.enable) {
      return true
    }
  }
}

function getGroundText(phase) {
  const value = ngcmdValue.value
  const earthCur = value['earthCur' + phase]
  const earthVol = value['earthVol' + phase]

  if (gndbox.value.modelId === 6 && earthCur !== undefined && earthCur !== null && getEnableness('earthCur', phase)) {
    return earthCur === 65535 ? '' : earthCur + 'A'
  } else if (gndbox.value.modelId === 7 && earthVol !== undefined && earthVol !== null && getEnableness('earthVol', phase)) {
    return earthVol === 65535 ? '' : earthVol + 'V'
  } else if (gndbox.value.modelId === 8) {
    let s = []
    if (earthCur !== undefined && earthCur !== null && getEnableness('earthCur', phase)) {
      s.push(earthCur === 65535 ? '' : earthCur + 'A')
    }
    if (earthVol !== undefined && earthVol !== null && getEnableness('earthVol', phase)) {
      s.push(earthVol === 65535 ? '' : earthVol + 'V')
    }
    return s.join(' ')
  }
  return ''
}

function getClass(phase, position) {
  let lowerPhase = phase.toLowerCase()
  let l = [
    'box-text', // 基本位置
    lowerPhase, // 颜色
    position, // 左右调整
    position + '-' + lowerPhase, // 高度调整
  ]
  // 左右调整
  for (let modifier of ['leftmost', 'rightmost', 'leftConjunct', 'rightConjunct']) {
    if (props.box[modifier]) {
      l.push(position + '-' + modifier.toLowerCase())
    }
  }
  return l.join(' ')
}

function getBoxNameClass() {
  let l = [
    'boxname', // 基本位置
  ]
  for (let modifier of ['leftmost', 'rightmost', 'leftConjunct', 'rightConjunct']) {
    if (props.box[modifier]) {
      l.push('boxname' + '-' + modifier.toLowerCase())
    }
  }
  return l.join(' ')
}

function getBoxIconClass() {
  let l = [
    'boxicon', // 基本位置
  ]
  for (let modifier of ['leftmost', 'rightmost', 'leftConjunct', 'rightConjunct']) {
    if (props.box[modifier]) {
      l.push('boxicon' + '-' + modifier.toLowerCase())
    }
  }
  return l.join(' ')
}

function onBoxNameClick() {
  store.commit('cables/setBoxClicked', ngcmd.value.boxId)
  store.commit('cables/setDeviceClicked', ngcmd.value.id)
}

function onBoxValueClick() {
  store.commit('cables/setCurrentDevice', ngcmd.value.id)
  router.push('devicecenter')
}

</script>

<style scoped>
.box-img {
  height: 190px;
}

.box-text {
  position: absolute;
  line-height: 20px;
  left: 90px;
  /*font-weight: bold;*/
  color: white;
  white-space: nowrap;
}

.junction {
  left: 90px;
}

.ground {
  left: 110px;
}

.phase {
  position: absolute;
  top: 40px;
}

.phase-c {
  left: 32px;
}

.phase-b {
  left: 60px;
}

.phase-a {
  left: 88px;
}

.a {
  color: #FFC909;
}

.b {
  color: #2CEB14;
}

.c {
  color: #FF2D00;
}

.ground-a {
  top: 130px;
}

.ground-b {
  top: 152px;
}

.ground-c {
  top: 174px;
}

.junction-a {
  top: 46px;
}

.junction-b {
  top: 73px;
}

.junction-c {
  top: 100px;
}

.boxname {
  position: absolute;
  top: 187px;
  left: 10px;
  line-height: 20px;
}

.boxname-leftmost {
  left: 120px;
}

.boxname-rightmost {
  left: 120px;
}

.boxname-leftconjunct {
  left: 30px;
}

.boxname-rightconjunct {
  left: 30px;
}

.boxicon {
  position: relative;
  height: 0;
  top: 187px;
  left: -30px;
  line-height: 20px;
}

.boxicon-leftmost {
  left: 75px;
}

.boxicon-rightmost {
  left: 75px;
}

.boxicon-leftconjunct {
  left: -10px;
}

.boxicon-rightconjunct {
  left: -7px;
}

.junction-leftmost {
  left: 190px
}

.junction-rightmost {
  left: 185px
}

.ground-leftmost {
  left: 225px
}

.ground-rightmost {
  left: 230px
}

.ground-leftconjunct {
  left: 140px
}

.ground-rightconjunct {
  left: 140px
}

.offline {
  filter: grayscale(1);
}
</style>
