<template>
  <div class="inner-wrapper">
    <div v-for="(line, lineNo) in boxes" :key="lineNo" :class="lineNo % 2 === 0 ? 'even-line' : 'odd-line'">
      <div v-for="(box, i) in line" :key="i" class="graph-wrapper">
        <box-graph v-if="typeof box === 'object'" :box="box"/>
        <img v-else-if="box === 'bottom-left'" src="@/assets/cable-graph/bottom-left.png" class="box-icon"/>
        <img v-else-if="box === 'top-left'" src="@/assets/cable-graph/top-left.png" class="box-icon"/>
        <img v-else-if="box === 'bottom-right'" src="@/assets/cable-graph/bottom-right.png" class="box-icon"/>
        <img v-else-if="box === 'top-right'" src="@/assets/cable-graph/top-right.png" class="box-icon"/>
        <img v-else-if="box === 'placeholder'" src="@/assets/cable-graph/placeholder.png" class="box-icon"/>
        <img v-else-if="box === 'line'" src="@/assets/cable-graph/line.png" class="box-icon"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, computed} from "vue"
import BoxGraph from "./BoxGraph.vue"
import {getCableName} from '@/utils/cableNameGen'
import {useStore} from "vuex"

const store = useStore()

const props = defineProps({
      id: {
        type: Number,
      },
    }
)

const cable = computed(() => {
  return store.getters["cables/getById"](props.id)
})

const boxes = computed(() => {
  return getBoxes()
})

const length = computed(() => {
  return boxes.value.length
})

function isEven(index) {
  return (index) % 8 > 3
}

function getBoxes() {
  if (!cable.value?.gndboxs) {
    return []
  }

  let sorted = [...cable.value.gndboxs].filter(x => x.enable).sort((a, b) => {
    return a.queue - b.queue
  })

  let r = [[]]
  let len = sorted.length
  for (let i = 0; i < sorted.length; i++) {
    let box = sorted[i]
    let boxInfo = {id: box.id}
    // first box
    if (i === 0) {
      if (box.modelId === 7) {
        r[0].push('placeholder')
      } else {
        boxInfo.leftmost = true
      }
      r[0].push(boxInfo)
      continue
    }

    // start of line
    if (i % 4 === 0) {
      let newLine = []
      if (isEven(i)) {
        newLine.push('bottom-right')  // even line
      } else {
        newLine.push('bottom-left')  // odd line
      }
      r.push(newLine)
    }

    let lastLine = r[r.length - 1]

    // last box
    if (i === len - 1) {
      if (box.modelId !== 7) {
        if (isEven(i)) {
          boxInfo.leftmost = true  // even line
        } else {
          boxInfo.rightmost = true  // odd line
        }
      }

      // 补全 placeholder
      if (box.modelId === 7) {
        lastLine.push(boxInfo)
        lastLine.push('placeholder')
      } else {
        let j = 0,
            total = 4 - (i+1) % 4
        if (total === 4) {
          total = 0
        }
        while (j < total) {
          lastLine.push('line')
          lastLine.push('line')
          j += 1
        }
        lastLine.push(boxInfo)
      }

      continue
    }

    // 判断 conjunct
    let nextBox = sorted[i+1]

    if (isConjuncted(box, nextBox)) {
      if (i % 4 === 3) {
        // 如果是行末，那么需要换行，需要修改 sorted
        sorted.splice(i, 0, '')
        len += 1
        lastLine.push('line')
        lastLine.push('line')

        if (isEven(i)) {
          lastLine.push('top-left')  // even line
        } else {
          lastLine.push('top-right')  // odd line
        }

        continue
      }
      // 否则
      if (isEven(i)) {
        boxInfo.rightConjunct = true
      } else {
        boxInfo.leftConjunct = true
      }
    }

    // 与上一个接地箱的 conjunct
    let prevBox = sorted[i - 1]
    if (isConjuncted(prevBox, box)) {
      if (isEven(i)) {
        boxInfo.leftConjunct = true
      } else {
        boxInfo.rightConjunct = true
      }
    }

    lastLine.push(boxInfo)

    // end of line
    if (i % 4 === 3) {
      if (isEven(i)) {
        lastLine.push('top-left')  // even line
      } else {
        lastLine.push('top-right')  // odd line
      }
    }
  }
  return r
}

function isConjuncted(box, nextBox) {
  return nextBox && box.conjunct && nextBox.conjunct && box.conjunct === nextBox.deviceCode && nextBox.conjunct === box.deviceCode
}

</script>

<style scoped>
.box-graph {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.inner-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  /*align-items: flex-start;*/
  justify-content: flex-start;
}

.cable-name {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.box-graph-wrap {
  flex-wrap: wrap;
}

.graph-wrapper {
  height: 190px;
}

.even-line {
  display: flex;
  flex-direction: row;
}

.odd-line {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.box-icon {
  height: 190px;
}

</style>
