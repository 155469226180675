<template>
  <div class="deviceList">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <i class="iconfont icon-vector"/>
        </span>
        <div class="d-flex">
          <span class="text mx-2">{{ t('cableCenter.warningList') }}</span>
        </div>
      </div>
      <div class="d-flex mt-1 jc-center body-box">
        <no-data v-if="tableData.length < 1" />
        <el-table
            v-show="tableData.length > 0"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            :max-height="props.height - 85"
        >
          <el-table-column prop="device" :label="t('cableCenter.warningBoxAndDevice')" width="150"/>
          <el-table-column prop="valueLabel" :label="t('cableCenter.warningItem')" width="110"/>
          <el-table-column prop="value" :label="t('cableCenter.warningValue')" width="100"/>
          <!--          <el-table-column prop="frequency" label="累计" width="60"/>-->
        </el-table>
      </div>
    </div>
  </div>

</template>

<script setup>
import {useStore} from "vuex"
import {
  ref,
  watch,
  onMounted,
  computed,
} from "vue"
import {getAlarms} from '@/api/alarms.js'
import noData from "@/view/dashboard/components/noData";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  height: {
    type: Number,
    default: 950,
  }
})

const store = useStore()

const tableData = ref([])

const getTableData = function () {
  getAlarms({
    cabid: store.state.cables.current,
    state: '1+2',
    ext: true
  })
      .then((r) => {
        if (r.code === 0 && r.data.list) {
          updateTable(r.data.list)
        } else {
          tableData.value = []
        }
      })
}

const updateTable = function (list) {
  let devices = {}
  let table = []
  for (let alarm of list) {
    // 暂时隐藏掉问题
    if (!alarm.dataCfg) {
      continue
    }

    let deviceInfo = devices[alarm.devDeviceID]
    if (deviceInfo === undefined) {
      let device = store.getters['cables/getDeviceById'](alarm.devDeviceID)
      let box = store.getters['cables/getDeviceById'](parseInt(device.boxId))
      deviceInfo = devices[alarm.devDeviceID] = {
        name: device.name,
        box: box.name,
      }
    }
    let valueUnit = alarm.dataCfg.dataUnit.unit
    let valueLabel = alarm.dataCfg.dataUnit.name
    let value = alarm.value + valueUnit
    if (alarm.value === 65535) {
      value = '-'
    }
    table.push({
      device: `${deviceInfo.box}-${deviceInfo.name}`,
      valueLabel,
      value,
      frequency: alarm.frequency
    })
  }
  tableData.value = table
}

onMounted(() => {
  store.dispatch('cables/request')
  getTableData()
})

watch(() => store.state.cables.current,
    () => getTableData())

const boxHeight = computed(()=>(props.height - 5) + 'px')
</script>

<style lang="scss" scoped>
$box-height: v-bind(boxHeight);
$box-width: 100%;
.deviceList {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;

    .el-table {
      padding: 0;
      text-align: left;

      .cell {
        span {
          display: none !important;
        }
      }
    }

  }
}

</style>
