<template>
  <div class="deviceList">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <i class="iconfont icon-vector"/>
        </span>
        <div class="d-flex">
          <span class="text mx-2">{{ t('cableCenter.deviceList') }}</span>
        </div>
      </div>
      <div class="d-flex mt-1 jc-center body-box">
        <el-table
            class="dev-list"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            :show-header="false"
            row-key="id"
            border
            max-height="388"
            :indent="0"
            ref="table">
          <el-table-column :label="t('cableCenter.deviceName')">
            <template #default="scope">
              <div class="cell-div">
                <BoxIcon :id="scope.row.id" />
                <el-link :class="getLinkClass(scope.row)" @click="onClick(scope.row)">
                  {{ scope.row.name }}
                </el-link>
                <div v-if="scope.row.numAlarms > 0" class="alarmSign">{{ scope.row.numAlarms }}</div>
                <template v-for="enfant in scope.row.enfants" :key="enfant.key">
                  <DeviceIcon
                    class="device-icon-clickable"
                    @click="onClick(enfant)"
                    :id="enfant.id"
                  />
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from "vuex"
import {
  ref,
  watch,
  onMounted,
} from "vue"
import {useRouter} from "vue-router"
import DeviceIcon from '@/components/pmsIcon/DeviceIcon.vue'
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const router = useRouter()

const store = useStore()
const tableData = ref([])
const cables = store.state.cables

const boxModelIds = [6, 7, 8]

const table = ref()

const onClick = function (row) {
  if (boxModelIds.includes(row.modelId)) {
    // table.value.toggleRowExpansion(row)
    store.commit('cables/setBoxClicked', row.id)
  } else {
    store.commit('cables/setBoxClicked', row.boxId)
    store.commit('cables/setDeviceClicked', row.id)
  }
}

const getLinkClass = function (row) {
  if (boxModelIds.includes(row.modelId)) {
    return 'ground-box'
  }
  if (row.online) {
    return 'online'
  } else {
    return 'offline'
  }
}

const getNumAlarms = function (id) {
  return store.getters['cables/getDeviceAlarmsById'](parseInt(id)).list.length
}

const getDeviceColumnObj = function (device) {
  let o = {id: device.id, name: device.name, type: device.model.name, modelId: device.modelId}
  o['online'] = store.getters['cables/getDeviceValueById'](parseInt(device.id)).online
  o['isAlarming'] = store.getters['cables/isDeviceAlarm'](parseInt(device.id))
  o['numAlarms'] = getNumAlarms(device.id)
  o['queue'] = device.queue
  return o
}

const updateDevices = function (cable) {
  let dataArray = [];
  let ground_boxes = cable.gndboxs
  if (!ground_boxes) {
    return
  }
  for (let j = 0; j < ground_boxes.length; j++) {
    let ground_box = ground_boxes[j]
    if (!ground_box.enable) {
      continue
    }
    let boxData = getDeviceColumnObj(ground_box)
    dataArray.push(boxData)
    if (ground_box.subdevs) {
      let devices = ground_box.subdevs
      if (!devices) {
        return
      }
      for (let m = 0; m < devices.length; m++) {
        if (dataArray[dataArray.length - 1].enfants === undefined) {
          dataArray[dataArray.length - 1].enfants = []
        }
        let device = devices[m]
        if (!device.enable) {
          continue
        }
        let deviceData = getDeviceColumnObj(device)
        deviceData['boxId'] = boxData.id
        dataArray[dataArray.length - 1].enfants.push(deviceData)
      }
    }
  }
  tableData.value = dataArray.sort((a, b)=>{
    return a.queue - b.queue
  })
}

watch(
    () => store.state.cables.current,
    (newVal) => {
      let cable = store.getters['cables/getById'](newVal)
      updateDevices(cable)
    },
)

watch(
    () => store.state.cables.actionStatus,
    () => {
      let cable = store.getters['cables/getById'](store.state.cables.current)
      updateDevices(cable)
    },
    {deep: true}
)

onMounted(
    () => {
      let cable = store.getters['cables/getById'](store.state.cables.current)
      updateDevices(cable)
    },
)

</script>

<style lang="scss" scoped>
$box-height: 470px;
$box-width: 100%;
.deviceList {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;

    .el-table {
      padding: 0;
      text-align: left;

      .cell {
        span {
          display: none !important;
        }
      }
    }

  }
}

.cell-div {
  display: flex;
  align-items: center;
}

.alarmSign {

  height: 20px;
  width: 20px;
  min-width: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: firebrick;
  padding: 3px;
  vertical-align: center;
  text-align: center;
  font-size: small;
}

.ground-box {
  color: whitesmoke;
}

.online {
  color: #33cea0;
  font-weight: bold;
}

.offline {
  color: dimgrey;
}

.device-icon-clickable {
  margin-left: 2px;
  cursor: pointer;
}
</style>

<style>
.dev-list .cell {
  display: flex;

}
</style>

