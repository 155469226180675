<template>
  <div class="content-box3 ">
    <el-row justify="space-between">
      <el-col :span="5" class="left-box">
        <div class="cableList">
          <dv-border-box-13>
            <CableList/>
          </dv-border-box-13>
        </div>
        <div class="deviceList">
          <dv-border-box-13>
            <DeviceList/>
          </dv-border-box-13>
        </div>
      </el-col>
      <el-col :span="14" class="middle-box">
        <div class="center bg-color-black cableTitle">
          {{ getCableName(store.getters['cables/getById'](store.state.cables.current)) }}
        </div>
        <dv-border-box-10 v-if="store.state.user?.userInfo?.nonMapMode" style="height: 950px">
          <el-scrollbar>
            <div class="wrapper">
              <cable-graph :id="store.state.cables.current"/>
            </div>
          </el-scrollbar>
        </dv-border-box-10>
        <div v-else style="height: 700px;">
          <map-cables class="map"/>
        </div>
      </el-col>
      <el-col :span="5" class="right-box">
        <div class="alarmList">
          <dv-border-box-13 :key="borderKey">
            <AlarmList :height="alarmListHeight"/>
          </dv-border-box-13>
        </div>
        <div class="image-viewer">
          <dv-border-box-13 :key="borderKey" v-show="fileList.length > 0">
            <ImageViewer :fileList="fileList"/>
          </dv-border-box-13>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import CableList from './CableList.vue'
import DeviceList from './DeviceList.vue'
import AlarmList from './AlarmList.vue'
import ImageViewer from '../components/imageViewer.vue'
import MapCables from '../components/mapCables/index.vue'
import {useStore} from 'vuex'
import {onMounted, ref, watch, computed} from 'vue'
import {getCableName} from '@/utils/cableNameGen'
import {getImageList} from "@/api/file"
import CableGraph from "@/components/cableGraph/CableGraphWrap.vue"

const store = useStore()

onMounted(() => {
  // store.dispatch("models/request");
  store.dispatch("companies/request");
  store.dispatch('cables/request');
})

const alarmListHeight = ref(950)
const fileList = ref([])

watch(() => store.state.cables.boxClicked,
    (val) => {
      let box = store.getters['cables/getDeviceById'](val)
      getImage(box.deviceCode)
    }
)

watch(() => store.state.cables.current,
    (val) => {
      let cable = store.getters['cables/getById'](val)
      if (cable.gndboxs && cable.gndboxs.length > 0) {
        store.commit('cables/setBoxClicked', cable.gndboxs[0].id)
      }
    }
)

function getImage(deviceCode) {
  getImageList(deviceCode).then((r) => {
    if (r.code === 0) {
      fileList.value = r.data
      if (fileList.value.length > 0) {
        alarmListHeight.value = 530
      } else {
        alarmListHeight.value = 950
      }
      borderKey.value += 1
    }
  })
}

const boxHeight = computed(() => (alarmListHeight.value + 'px'))

const borderKey = ref(0)

</script>

<style scoped>
.cableTitle {
  color: #db9e3f;
  margin-top: 20px;
  margin-left: 20px;
  padding: 20px;
  font-size: 30px;
  line-height: 40px;
  border-radius: 5px;
}

.cableList {
  height: 475px;
}

.deviceList {
  height: 477px;
}

.alarmList {
  --alarm-list-height: v-bind(boxHeight);
  height: var(--alarm-list-height);
}

.image-viewer {
  height: 420px;
}

.wrapper {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 120px;
}

.placeholder {
  height: 100px;
}

.border-box-wrapper {
  max-height: 600px;
}
</style>
