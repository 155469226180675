<template>
  <el-carousel height="400px" class="viewer-carousel">
    <el-carousel-item v-for="item in props.fileList" :key="item">
      <el-image class="viewer-image" :src="getImagePath(item)" fit="cover"
                hide-on-click-modal
                :preview-src-list="props.fileList.map(e=>getImagePath(e))"
                :preview-teleported="true"/>
    </el-carousel-item>
  </el-carousel>
</template>

<script setup>
const props = defineProps({
  fileList: {
    type: Object,
    default() {return []},
  }
})

function getImagePath(filename) {
  if (process.env.NODE_ENV === 'development') {
    return 'https://www.dataf.store/images/' + filename
  } else {
    return 'images/' + filename
  }
}
</script>

<style scoped>
.viewer-carousel {

}

.viewer-image {
  margin-top: 24px;
  margin-left: 10px;
  height: 380px;
  width: 373px;
}
</style>