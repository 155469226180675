import service from '@/utils/request'

const url = '/api/v1/file'

export const getImageList = (deviceCode) => {
  return service({
    url,
    method: 'get',
    params: {deviceCode},
  })
}

export const deleteFile = (filename) => {
  return service({
    url,
    method: 'delete',
    params: {filename}
  })
}

export const getCount = (deviceCodes) => {
  return service({
    url,
    method: 'get',
    params: {
      deviceCode: deviceCodes,
      count: true,
    }
  })
}